html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

#root {
  height: 100%;
  --ReactInputVerificationCode-itemWidth: 2.4rem;
  --ReactInputVerificationCode-itemHeight: 3.2rem;
  --ReactInputVerificationCode-itemSpacing: 0.7rem;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

